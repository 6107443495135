.register__status {
  margin-top: 100px;
  text-align: center;
}

.register__form {
  width: 400px;
  margin: 100px auto;
}

.register__confirm {
  width: 200px;
  margin: 50px auto;
}

.register__submit {
  float: right;
  margin: 10px 3px;
}

.register__spinner {
  float: right;
  margin: 15px 10px 0 0;
}

.register__error {
  float: left;
  margin: 10px 3px;
}
