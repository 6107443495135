.ladder {
  display: flex;
}

.ladder__left,
.ladder__right {
  padding: 25px;
}

.ladder__left {
  flex-basis: 41%;
  min-width: 365px;
  padding-bottom: 0;
}

.ladder__right {
  flex-basis: 59%;
  min-width: 570px;
  padding-left: 0;
}

.ladder__search {
  display: flex;
}

@media (max-width: 1126px) {
  .ladder__search {
    flex-wrap: wrap;
  }
  .ladder__search__group {
    flex-basis: 100%;;
  }
  .ladder__search__label {
    width: 72px;
  }
  .ladder__search__hero {
    display: none;
  }
  .ladder__search .custom-select {
    margin-right: 25px;
  }
}

.ladder__search .custom-select,
.ladder__search .form-control {
  flex-basis: 150px;
}

.ladder__search .custom-select {
  flex-grow: 1;
}

.ladder__search__label {
  margin-right: 10px;
}

.ladder__search__label + .form-check-inline {
  margin-right: 25px;
}

.ladder__search__group:last-child .ladder__search__label + .form-check-inline {
  margin-right: 0;
}

.ladder__search__mod,
.ladder__search__hero {
  margin-left: 25px;
}

.ladder__search__hero {
  margin-right: 25px;
}

.ladder__search__spinner {
  position: relative;
  top: -43px;
  left: 10px;
}

.ladder__search + .ladder__search,
.ladder__info {
  margin-bottom: 10px;
}

.ladder__info__label {
  clear: both;
  float: left;
  width: 100px;
  color: rgb(193, 161, 129);
}

.ladder__info__value {
  float: left;
}

.ladder__copy-button {
  position: relative;
  top: -2px;
  margin-right: 10px;
}

.ladder__copy-icon {
  width: 12px;
}

.ladder__ladder-id {
  margin-right: 10px;
}

.ladder__ladder-id-copied {
  color: green;
}

.ladder__left__add {
  height: 100px;
  margin-top: -125px;
}

.d2s-hero__section + .d2s-hero__section {
  width: 320px;
}

.ladder__current-ladders {
  margin-bottom: 0;
}

.ladder__current-start-date {
  color: darkgray;
}

.ladder__current-ladders__name {
  margin-right: 10px;
}

.ladder__right__add {
  position: absolute;
  top: 75px;
  right: 25px;
  width: 240px;
  height: 480px;
}

/*
  Private ladder
*/
.ladder__create {
  margin-top: 1rem;
  color: rgb(193, 161, 129);
}

.ladder__private__count {
  color: darkgray;
}

.ladder__private__list {
  height: auto;
  margin-top: 8px;
}

.ladder__private__th {
  text-decoration: underline;
}

.ladder__private__create-button {
  padding: 0;
}

.ladder__private__th,
.ladder__private__btn {
  color: rgb(193, 161, 129);
}

.ladder__private__td {
  padding-right: 10px;;
}

.ladder__private__btn:hover {
  color: rgb(6, 165, 221);
}

.ladder__private {
  width: 500px;
  margin: 0 auto;
}

.ladder__private__wrapper {
  display: inline-block;
}

.ladder__private__checkboxes {
  display: flex;
  padding-left: 87px;
}

.ladder__private__checkbox-label {
  margin-right: 10px;
}

.ladder__private__checkbox-label + .form-check-inline {
  margin-right: 25px;
}

.ladder__private__label,
.ladder__private__control {
  float: left;
}

.ladder__private__label {
  width: 88px;
  margin-top: 8px;
  margin-bottom: 0;
}

.ladder__private__control {
  width: 400px;
}

.ladder .react-datepicker-wrapper {
  width: auto;
}

.ladder__private__id-group {
  margin-top: -6px;
}

.ladder__private__id-group .ladder__private__control {
  background-color: #343a40;
}

.ladder__private__submit {
  float: right;
  margin: 10px 3px;
}

.ladder__private__spinner,
.ladder__private__check-icon {
  float: right;
  margin: 15px 10px 0 0;
}

.ladder__private__error {
  float: left;
  margin: 10px 3px;
}

.ladder__close {
  float: right;
}
