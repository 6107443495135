.account {
  max-width: 500px;
  margin: 56px auto;
}

.account__wrapper {
  max-width: 400px;
  margin: 0 auto;
}

.account__form__group {
  display: flex;
  position: relative;
}

.account__label {
  width: 98px;
  margin: 8px 0 0;
}

.account__api__refresh {
  position: absolute;
  top: 0.375rem;
  right: 0.75rem;
  margin: 0;
  padding: 0;
}

.account__api__refresh:hover {
  color: #28a745;
}

.account__api__refresh-icon {
  width: 1.1rem;
  height: 1.1rem;
}

.account__submit {
  float: right;
  margin: 10px 3px;
}

.account__spinner {
  float: right;
  margin: 15px 10px 0 0;
}

.account__error {
  float: left;
  margin: 10px 3px;
}

.account__logout {
  float: right;
  margin: 10px 3px;
}

.account__donations,
.account__donate {
  margin-top: 75px;
  text-align: center;
}

.account__donations + .account__donate {
  margin-top: 50px;
}

.account__donations {
  background-color: #1d2127;
  padding: 10px;
}

.account__donate__suggestion {
  margin-bottom: 25px;
}

.account__donate__header {
  color: lime;
}

.account__donate__icon {
  position: relative;
  top: -2px;
  height: 24px;
  margin-right: 10px;
}

.dbox-donation-button {
  display: inline-block;
  margin-top: 25px;
}
