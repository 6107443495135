button {
  background-color: transparent;
  color: #adadad;
  border: 0;
}

button:hover {
  text-decoration: underline;
}

.flex-break {
  flex-basis: 100%;
}

.text-info {
  color: rgb(6, 165, 221) !important;
}
