.login__form {
  max-width: 400px;
  margin: 100px auto;
}

.login__submit,
.login__reset,
.login__register {
  float: right;
  margin: 10px 3px;
}

.login__reset {
  clear: both;
}

.login__register {
  float: left;
}

.login__spinner {
  float: right;
  margin: 15px 10px 0 0;
}

.login__error {
  float: right;
  margin: 10px 10px 0 0;
}

.login__reset__message {
  margin-top: 100px;
  text-align: center;
}
