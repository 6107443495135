.home {
  max-width: 1440px;
  margin: 25px auto;
  text-align: center;
}

.home__logo {
  width: 256px;
  height: auto;
}

.home__header {
  margin: 25px;
  font-size: 1rem;
  color: lime;
}

.home__new {
  color: #9146ff;
}

.home__download {
  margin-top: 50px;
}

.home__download__href {
  color: darkgoldenrod;
  text-decoration: underline;
}

.home__download__href:hover {
  color: darkgoldenrod;
  text-decoration: none;
}

.home__eg-title {
  margin-top: 100px;
  font-size: 2rem;
  color: rgb(6, 165, 221);
}

.home__eg-img {
  max-width: 100%;
  margin: 0 auto;
}

.home__footer{
  margin: 25px;
  font-size: 0.8rem;
}
