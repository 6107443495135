.ladder-list__wrapper {
    display: flex;
}

.ladder-list__content {
  display: inline-block;
  flex-basis: 100%;
  overflow: hidden;
}

.ladder-list__scroll {
  display: inline-block;
  flex: 0 0 64px;
}

.ladder-list__scroll-bar {
  width: 4px;
  margin: 0 auto;
  background-color: #000;
}

.ladder-list__scroll-handle {
  position: relative;
  left: -22px;
  width: 48px;
  height: auto;
  user-select: none;
}

.ladder-list__header {
  border-bottom: 1px solid #131516;
  color: rgb(193, 161, 129);
}

.ladder-list__item {
  display: flex;
  white-space: nowrap;
  cursor: pointer;
}

.ladder-list__item:hover,
.ladder-list__item--active {
  background-color: #131516;
}

.ladder-list__item__rank {
  flex-basis: 74px;
  color: rgb(193, 161, 129);
}

.ladder-list__item__name {
  flex-basis: 100%;
  overflow: hidden;
}

.ladder-list__hero-info,
.ladder-list__hero-info__general,
.ladder-list__hero-info__mod,
.ladder-list__hero-info__name {
  display: inline-block;
}

.ladder-list__hero-info__general {
  width: 142px;
}

.ladder-list__hero-info__general--dead {
  text-decoration: line-through;
}

.ladder-list__hero-info__mod {
  width: 149px;
}

.ladder-list__item__tombstone-icon {
  width: 1.2rem;
  height: 1.2rem;
  position: relative;
  top: -2px;
  margin-left: 11px;
  opacity: 0.5;
}
