.nav {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.nav__logo {
  font-size: 50px;
  line-height: 50px;
  position: relative;
  top: -2px;
  left: 2px;
  font-weight: bold;
  color: #6b6b6b;
}

.nav__logo:hover {
  text-decoration: none;
}

.nav__items {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 572px) {
  .nav__person-icon {
    display: none;
  }
}

.nav__item {
  display: inline-block;
  padding: 10px 30px;
}

.nav__person-icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
